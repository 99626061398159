@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,800;1,400;1,800&display=swap");
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	--grayBgColor: #f5f5f5;
	--borderColor: #00000044;
	--borderColor: #00000083;
}

/* for disabling mui datagrid cell select */
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
	/* outline: none !important; */
	outline: none;
}
